import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getLineUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_lineusers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_lineuser', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_lineuser', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/edit_lineuser/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_lineuser/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_lineuser', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
