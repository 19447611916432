import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function useLineUserList() {
  // Use toast
  const toast = useToast()

  const refLineUserListTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('Line User') }),
    display_name: `${t('Display Name')} » ${t('User Id')} » ${t('Language')} » ${t('ID')}`,
    status: `${t('Status')} » ${t('Follow')} » ${t('Unfollow')} » ${t('Block')}`,
    richmenu: `${t('Rich Menu')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'display_name', sortable: true, label: msgList.display_name },
    { key: 'status', sortable: false, label: msgList.status },
    { key: 'richmenu', sortable: false, label: msgList.richmenu },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalLineUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('display_name')
  const isSortDirDesc = ref(true)
  const locale = ref('th')
  const nameFilter = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const dataMeta = computed(() => {
    const localItemsCount = refLineUserListTable.value ? refLineUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLineUsers.value,
    }
  })

  const refetchData = () => {
    refLineUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale, nameFilter], () => {
    refetchData()
  })

  const getLineUsers = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      name: nameFilter.value,
      lineOaId: id,
    }
    store
      .dispatch('line-user-store/getLineUsers', params)
      .then(response => {
        const { lineusers, total } = response.data
        callback(lineusers)
        totalLineUsers.value = total
        localStorage.setItem('filterLineUserList', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isStatusVariant = isname => {
    if (isname === true) return 'success'
    if (isname === false) return 'danger'
    return 'danger'
  }

  const isStatusToText = isname => {
    if (isname === true) return 'Yes'
    if (isname === false) return 'No'
    return 'No'
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    getLineUsers,
    tableColumns,
    perPage,
    currentPage,
    totalLineUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refLineUserListTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,

    nameFilter,
  }
}
